<script setup lang="ts">
interface IProps {
  size?: string;
  color?: string;
}

const { size } = withDefaults(defineProps<IProps>(), {
  size: "36",
  color: "#FFF",
});
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM22 16C22 16.2652 21.8946 16.5196 21.7071 16.7071C21.5196 16.8946 21.2652 17 21 17H13.4138L15.7075 19.2925C15.8004 19.3854 15.8741 19.4957 15.9244 19.6171C15.9747 19.7385 16.0006 19.8686 16.0006 20C16.0006 20.1314 15.9747 20.2615 15.9244 20.3829C15.8741 20.5043 15.8004 20.6146 15.7075 20.7075C15.6146 20.8004 15.5043 20.8741 15.3829 20.9244C15.2615 20.9747 15.1314 21.0006 15 21.0006C14.8686 21.0006 14.7385 20.9747 14.6171 20.9244C14.4957 20.8741 14.3854 20.8004 14.2925 20.7075L10.2925 16.7075C10.1995 16.6146 10.1258 16.5043 10.0754 16.3829C10.0251 16.2615 9.99922 16.1314 9.99922 16C9.99922 15.8686 10.0251 15.7385 10.0754 15.6171C10.1258 15.4957 10.1995 15.3854 10.2925 15.2925L14.2925 11.2925C14.4801 11.1049 14.7346 10.9994 15 10.9994C15.2654 10.9994 15.5199 11.1049 15.7075 11.2925C15.8951 11.4801 16.0006 11.7346 16.0006 12C16.0006 12.2654 15.8951 12.5199 15.7075 12.7075L13.4138 15H21C21.2652 15 21.5196 15.1054 21.7071 15.2929C21.8946 15.4804 22 15.7348 22 16Z"
      :fill="color"
    />
  </svg>
</template>
