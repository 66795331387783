<script setup>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import IconArrowCicleRight from "@/components/icons/ArrowCircleRight";
import IconArrowCicleLeft from "@/components/icons/ArrowCircleLeft";

const props = reactive({
  imgSlider: [
    {
      id: 1,
      name: "Sesa.id",
      logo: "/images/sesa-logo.png",
      review:
        "“FLIK provide solutions and insight based on our business problem. Their technology is here to help us achieve our business goal. They listen to your problem”",
      reviewer: "Reni Rahmawati, E-Commerce Manager",
      img: "/images/sesa.jpg",
    },
    {
      id: 2,
      name: "ZMNow.id & Geraihawa.co.id",
      logo: "/images/zmnowxgeraihawa.png",
      review:
        "“When using FLIK, we experience a lot of convenience in running our business. The shipping process, payments, as well as the return/refund process are well-organized.”",
      reviewer:
        "Fiqih Wardana, Sales Executive & Brand Executive Zaskia Mecca Group",
      img: "/images/zmnow.jpg",
    },
  ],
});

const carousel = ref(null);
function prev() {
  carousel.value.prev();
}

function next() {
  carousel.value.next();
}
</script>
<template>
  <div class="flex min-h-screen">
    <div
      class="flex flex-col p-8 md:p-10 lg:min-w-[380px] w-full lg:max-w-[520px]"
    >
      <div class="mb-10">
        <img
          src="/images/flik-logo.png"
          alt="Flik Logo"
          class="object-contain h-7"
          height="30"
        />
      </div>
      <div class="flex-1 flex flex-col justify-center">
        <slot />
      </div>
    </div>
    <div class="flex-1 md:hidden lg:flex lg:flex-1">
      <Carousel ref="carousel" :wrap-around="true">
        <Slide v-for="slide in props.imgSlider" :key="slide">
          <div class="w-full h-screen relative">
            <img class="w-full h-full object-cover" :src="slide.img" />

            <div
              class="absolute bottom-0 flex items-end text-white p-10"
              style="
                background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 0) 0%,
                  #000 90%
                );
              "
            >
              <div class="flex-1 text-left">
                <!-- <h2 class="text-left text-3xl font-bold">{{ slide.name }}</h2> -->
                <img :src="slide.logo" />
                <p class="my-4 text-2xl font-medium">{{ slide.review }}</p>
                <span>{{ slide.reviewer }}</span>
              </div>
              <div>
                <button class="mr-4" @click="prev">
                  <IconArrowCicleLeft />
                </button>
                <button @click="next">
                  <IconArrowCicleRight />
                </button>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>
